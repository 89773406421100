import React from 'react'
import {
  FacebookShareButton,
  // GooglePlusShareButton,
  // LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  // VKShareButton,
  // OKShareButton,
  // TelegramShareButton,
  WhatsappShareButton,
  // RedditShareButton,
  EmailShareButton,
  // TumblrShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  FacebookIcon,
  // TwitterIcon,
  // GooglePlusIcon,
  // LinkedinIcon,
  PinterestIcon,
  // VKIcon,
  // OKIcon,
  // TelegramIcon,
  WhatsappIcon,
  // RedditIcon,
  // TumblrIcon,
  // MailruIcon,
  EmailIcon
  // LivejournalIcon,
} from 'react-share'

import x from '../static/social-icons/x.svg'

const ArticleSocialShare = (props) => (
  <div className='sharethis-inline-share-buttons'>
    <p>Share this article:</p>
    <FacebookShareButton className={props.className} quote={props.quote} url={props.url}>
      <FacebookIcon size={32} /><span>Share</span>
    </FacebookShareButton>

    <TwitterShareButton {...props}>
      <img style={{ marginBottom: 8 }} src={x} title='Share on Twitter' alt='Share on Twitter' /><span>Tweet</span>
    </TwitterShareButton>

    {props.media &&
      <PinterestShareButton {...props}>
        <PinterestIcon size={32} /><span>Share</span>
      </PinterestShareButton>}

    <WhatsappShareButton {...props} separator=' '>
      <WhatsappIcon size={32} /><span>Share</span>
    </WhatsappShareButton>

    <EmailShareButton {...props} separator=' '>
      <EmailIcon size={32} /><span>Email</span>
    </EmailShareButton>
  </div>
)

export default ArticleSocialShare
