import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import iab from '../../static/general/iab.gif'
import { Mobile } from 'ion-media'
import Ad from '../Ad'

const Footer = props => (
  <>
    <footer role='contentinfo' className={props.wallpaperRendered ? 'wallpaper-present' : ''}>
      <div className='wrapper'>
        <nav>
          <ul>
            <li>
              <div className='international-sites'>
                <select onChange={(event) => {
                  window.open(event.target.value, '_blank')
                }}
                >
                  <option value='#'>Glamour International</option>
                  <option value='http://www.glamour.bg/'>Bulgaria</option>
                  <option value='https://glamour.globo.com/'>Brazil</option>
                  <option value='http://www.glamour.de/'>Germany</option>
                  <option value='http://www.glamour.hu/'>Hungary</option>
                  <option value='http://www.glamour.mx/'>Mexico and Latin America</option>
                  <option value='http://www.glamour.pl/'>Poland</option>
                  <option value='http://www.glamour.ro/'>Romania</option>
                  <option value='http://www.glamour.es/'>Spain</option>
                  <option value='http://www.glamourmagazine.co.uk/'>UK</option>
                  <option value='https://www.glamour.com'>United States</option>
                </select>
              </div>
            </li>
            <li>
              <Link to='/subscribe-glamour'>Subscribe</Link>
            </li>
            <li>
              <Link to='/my-notifications'>Notifications</Link>
            </li>
            <li>
              <Link to='/my-bookmarks'>Bookmarks</Link>
            </li>
            <li>
              <Link to='/to-advertise'>Advertise</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
            <li>
              <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </nav>
        <p>
          &copy; {new Date().toLocaleDateString('en-ZA', { year: 'numeric' })}{' '}
          Cond&eacute; Nast. All rights reserved. Glamour&reg; is a registered
          trademark of Cond&eacute; Nast New Markets Europe/Africa, Inc. used
          under license by CONTENT NATION MEDIA (PTY) ltd. The
          material on this site may not be reproduced, distributed, transmitted,
          cached, or otherwise used, except with the prior written permission of
          the copyright owner.
        </p>
        <img src={iab} alt='iab South Africa' />
      </div>
    </footer>
    <Mobile>
      <Ad
        path={`/${process.env.RAZZLE_DFP_CODE}/${props.section || 'homepage'}`}
        targeting={{ 'mobile-leaderboard': 'sticky' }}
        slotId='ad-leaderboard-sticky'
        className='ad-leaderboard-sticky'
        mobile={[[320, 50]]}
      />
    </Mobile>
  </>
)

export default Footer
