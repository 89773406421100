import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadable from '@loadable/component'
import { Sidebar, Segment, Menu } from 'semantic-ui-react'
import ServiceWorker from './ServiceWorker'
import ScrollToTop from 'ion-scrolltotop'
import { Mobile } from 'ion-media'
import { url2section } from 'ion-sections'
import { enableSingleRequest, enableLazyLoad } from 'ion-ads'

import Header from './Shell/Header'
import Footer from './Shell/Footer'
import NavMenu from './Shell/NavMenu'
import PushPrompt from './PushPrompt'

import FaviconApple from '../static/favicon/apple-touch-icon.png'
import Favicon32 from '../static/favicon/favicon-32x32.png'
import Favicon16 from '../static/favicon/favicon-16x16.png'
import FaviconSafari from '../static/favicon/safari-pinned-tab.svg'
import FaviconICO from '../static/favicon/favicon.ico'
import FaviconSVG from '../static/favicon/favicon.svg'

import logo from '../static/general/glamour.svg'

import { hideMenu, toggleMenu, toggleSearch, pageView, onSlotRenderEndedLeft, onSlotRenderEndedRight } from '../../store/app'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'

import CookieForm from './CookieForm'

import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/menu.min.css'
// import 'semantic-ui-css/semantic.min.css'
import '../styles/app.scss'
import NewsletterPage from '../pages/NewsletterPage'
import { ArticlePageSkeleton, DefaultSectionPageSkeleton } from './Skeletons'

export const HomePage = loadable(() => import('../pages/LandingPages/HomePage'), {
  fallback: <DefaultSectionPageSkeleton />
})
export const NotFound = loadable(() => import('../pages/NotFound'))
export const DefaultSection = loadable(() => import('./DefaultSection'), { fallback: <DefaultSectionPageSkeleton /> })
export const Article = loadable(() => import('../pages/Article'), { fallback: <ArticlePageSkeleton /> })
export const StaticPage = loadable(() => import('../pages/StaticPage'))
export const SearchPage = loadable(() => import('../pages/SearchPage'))
export const PushNotificationsManagementPage = loadable(() => import('../pages/PushNotificationManagementPage'))
export const AuthorsSectionPage = loadable(() => import('../pages/AuthorsSectionPage'))
export const BookmarksPage = loadable(() => import('../pages/BookmarksPage'))

// GPT.enableSingleRequest()

export class App extends Component {
  constructor (props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      wingbannerLeftActive: false,
      wingbannerRightActive: false,
      hamburgerActive: false,
      useSmall: false
    }
    // this.props.pageView(this.props.location.pathname)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.pageView(this.props.location.pathname)
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const useSmall = window.scrollY > (125 + (this.state.useSmall ? -25 : 0))
    if (useSmall !== this.state.useSmall) { this.setState({ useSmall }) }
  }

  toggleSearch () {
    this.props.toggleSearch()
  }

  toggleMenu () {
    this.props.toggleMenu()
  }

  hideMenu () {
    if (this.props.showMenu) {
      this.props.hideMenu()
    }
  }

  render () {
    return (
      <>
        <div className='app'>
          <Helmet
            defaultTitle={process.env.RAZZLE_SITE_TITLE}
          >
            <html lang='en' />
            <link rel='icon' href={FaviconSVG} type='image/svg+xml' />
            <link rel='icon' type='image/x-icon' href={FaviconICO} />
            <link rel='apple-touch-icon' sizes='180x180' href={FaviconApple} />
            <link rel='icon' type='image/png' sizes='32x32' href={Favicon32} />
            <link rel='icon' type='image/png' sizes='16x16' href={Favicon16} />
            <link rel='mask-icon' href={FaviconSafari} color='#000000' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
            <meta property='fb:app_id' content='1915095138801045' />
            <meta property='og:type' content='article' />
            <meta charset='utf-8' />
            <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
            <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta property='og:image' content={logo} />
            <meta itemprop='inLanguage' content='en' />
            <meta itemprop='image' content={logo} />
            <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='google-site-verification' content='HddMcLw_KNBdDRFGEB3PgvGZOwDeN2mBWccTmTaOGf0' />
            <meta itemprop='image' content={logo} />
            <meta itemprop='thumbnailUrl' content={logo} />
            <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image:src' content={logo} />
            <meta name='facebook-domain-verification' content='g8nc9qtjhsl1x3lnhwdttr375659q1' />
            <link rel='canonical' itemprop='url' href={process.env.RAZZLE_SITE_URL + (this.props.location.pathname === '/' ? '' : this.props.location.pathname)} />
            <body className={(this.props.showMenu ? 'menu-open' : 'menu-closed') + ((this.props.wingbannerLeftActive || this.props.wingbannerRightActive) ? ' dfp-take-over-ad' : '')} />
          </Helmet>
          <Sidebar.Pushable as={Segment}>
            <Mobile>
              <Sidebar as={Menu} animation='push' width='wide' visible={this.props.showMenu} icon='labeled' vertical>
                <NavMenu toggleMenu={this.toggleMenu} />
              </Sidebar>
            </Mobile>
            <Sidebar.Pusher dimmed={this.props.showMenu} onClick={() => this.hideMenu}>
              <Header useSmall={this.state.useSmall} toggleMenu={this.toggleMenu} toggleSearch={this.toggleSearch} {...this.props} />
              <main id='main-content' role='main' className='content'>
                <ScrollToTop>
                  <Switch>
                    <Route exact path='/' render={p => <HomePage {...this.props} />} />
                    <Route exact path='/shell' render={() => <div>Loading...</div>} />
                    <Route exact path='/newsletter/thankyou' render={() => <NewsletterPage hasSubmitted />} />
                    <Route exact path='/newsletter' component={NewsletterPage} />
                    <Route
                      exact path='/search' render={(props) =>
                        <SearchPage
                          {...this.props}
                          twitterName={process.env.RAZZLE_SITE_TWITTER_USER}
                          titleTemplate={'%s | ' + process.env.RAZZLE_SITE_TITLE}
                        />}
                    />
                    <Route
                      exact path='/my-notifications' render={() => (
                        <PushNotificationsManagementPage
                          {...this.props}
                          section='news'
                          mostReadSection='news'
                          title='Notifications Management'
                          description='View your notifications history and manage the entities that you follow.'
                        />
                      )}
                    />
                    <Route
                      path='/authors' exact render={props =>
                        <AuthorsSectionPage
                          section='authors'
                          title='Authors Page'
                          description='Authors Page'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/my-bookmarks' render={() => (
                        <BookmarksPage
                          {...this.props}
                          section='news'
                          mostReadSection='news'
                          title='Bookmarks'
                          description='Articles which the user has bookmarked for offline reading.'
                        />
                      )}
                    />
                    <Route
                      exact path='/:page(terms-and-conditions)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Terms and Conditions | Glamour South Africa'
                          description='Definitions and Interpretation “Account” refers to a user’s subscription account for use of Condé Nast services. “Condé Nast” refers to Condé Nast Independent Magazines (Pty) Ltd, situate at 220 Loop Street, CPI House, 2nd Floor, Cape Town ,8001. “Content” includes any information, cookies, data, text, streaming content, software, designs, graphics, images, photographs, music, sounds, messages, tags, hyperlinks or …'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(terms-conditions)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Competition Terms and Conditions | Glamour South Africa'
                          description='Terms and Conditions for Competitions on Glamour South Africa'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(privacy-policy)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Privacy Policy | Glamour South Africa'
                          description='Condé Nast is committed to ensuring users’ online safety as far as is reasonably possible. Users should ensure should not divulge their user names and passwords to any other persons. Condé Nast will not disclose users’ information to any third parties without users’ consent, but may under certain circumstances disclose information to national and international …'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(masthead)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Glamour SA | Digital Masthead'
                          description='Get in touch with the people behind GLAMOUR, we would love to hear from you! Visit our website to find out how you can get in contact with the team and we will respond as soon as possible.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(subscribe-glamour)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Subscribe to SA’s Glamour Magazine | Glamour SA'
                          description='Subscribe to GLAMOUR magazine.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(to-advertise)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Advertise | Glamour South Africa'
                          description='WHAT IS GLAMOUR.CO.ZA? Dynamic, exciting, fabulously fashionable and fun, GLAMOUR.co.za is a network of 2 million-plus style-conscious readers with a large disposable income and a passion for shopping. And just like these young women, GLAMOUR.co.za loves beauty, new trends, A-list celebrities and getting ahead. From catwalk directions to daily style diaries, makeup to relationships, covetable …'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(contact-us)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Contact Us | Glamour South Africa'
                          description='Got a question, comment or request? Simply fill in the fields below and write away. Your email will be answered by the Glamour online team and we’ll be in touch as soon as possible. Don’t forget to sign up for the e-newsletter to be the first to hear about events and promotions at Glamour South …'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(help)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Help'
                          description='Help pages for navigating your way around the site.'
                          {...props}
                        />}
                    />
                    <Route
                      exact path='/:page(women-of-the-year)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Women of the Year | Glamour South Africa'
                          description=''
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(glamours-most-glamourous)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Glamours Most Glamorous | Glamour South Africa'
                          description=''
                          {...this.props}
                        />}
                    />
                    {/* Defaults and direct article access */}
                    <Route path='/preview' render={props => <Article {...this.props} {...props} url='preview' contentKey={999999} />} />
                    <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:contentKey([0-9]{5,})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={props => <Redirect to={'/' + props.match.params.section} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)' render={props => <DefaultSection {...this.props} {...props} twitterName={process.env.RAZZLE_SITE_TWITTER_USER} />} />
                    {/* <Route exact path='/404' component={NotFound} {...this.props} /> */}
                    <Route path='*' component={NotFound} {...this.props} />
                  </Switch>
                </ScrollToTop>
              </main>

              <PushPrompt />
              <CookieForm />
              <Footer showSubscribe={!(this.props.location.pathname === '/subscribe/newsletters')} section={url2section(this.props.location.pathname)} />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          <ServiceWorker />
        </div>
      </>
    )
  }
}

/**
 * enable Single Request Mode for Ads
 *
 * This setting requires a call to `performSingleRequest` to initiate the fetch. This is currently achieved using sagas,
 * leveraging `onSlotRegistered` to trigger an action, and debouncing the events to catch the last one in the series to
 * trigger the `performSingleRequest` call.
 */
enableSingleRequest()
if (process.env.RAZZLE_ENABLE_GAM && process.env.RAZZLE_ENABLE_LAZYLOAD) {
  enableLazyLoad()
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ hideMenu, toggleMenu, toggleSearch, pageView, fetchArticles, fetchMoreArticles, onSlotRenderEndedLeft, onSlotRenderEndedRight }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
